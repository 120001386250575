import axios from 'axios';

import { isTokenExpired } from '../helpers/jwtHelpers';
import { OutgoingHttpHeaders } from 'http';

function isAuthenticated() {
  // Checks if there is a saved token and it's still valid
  const token = localStorage.getItem('token');
  if (token) {
    return !isTokenExpired(token);
  } else {
    return false;
  }
}

export function getToken() {
  const token = localStorage.getItem('token');
  return token ? token : 'undefined';
}

export const getBaseUrl = (): string => {
  const bases = {
    localhost: 'http://localhost:8000/be/',
    dev: 'https://dev.storyzen.com/be/',
    test: 'https://test.storyzen.com/be/',
    prod: 'https://storyzen.com/be/',
  };

  const { hostname } = window.location;

  switch (true) {
    case hostname.includes('localhost'):
      return `${bases['localhost']}`;
    case hostname.includes('dev'):
      return `${bases['dev']}`;
    case hostname.includes('test'):
      return `${bases['test']}`;
    default:
      return `${bases['prod']}`;
  }
};

const createApi = (router: 'sz') => {
  const headers: OutgoingHttpHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (isAuthenticated()) {
    headers['Authorization'] = getToken();
  }

  const base = getBaseUrl();

  return axios.create({
    baseURL: `${base}${router}/`,
    headers: headers,
    withCredentials: true,
  });
};

const api = () => createApi('sz');

export default api;
